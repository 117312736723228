import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';

import { RichText } from 'prismic-reactjs';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Row from '../components/Row';
import Col from '../components/Col';
import SliceZone from '../components/SliceZone';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidthText};
  margin: ${rem(50)} auto 0;
  padding-bottom: ${rem(80)};
`;

function App({ data }) {
  const { page } = data.prismic;

  // console.log(data);

  if (!page) {
    return null;
  }

  return (
    <Layout>
      <SEO
        title={RichText.asText(page.seo_meta_title || page.title)}
        description={page.seo_description}
      />

      <Hero
        heading={RichText.asText(page.title)}
        image={page.featured_imageSharp}
        pageTitle
      />

      <Container>
        <Row>
          <Col>
            <SliceZone slices={page.body} />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

App.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query($uid: String!) {
    prismic {
      page(lang: "en-gb", uid: $uid) {
        _meta {
          uid
        }
        title
        body {
          ... on PRISMIC_PageBodyCode_block {
            type
            label
            primary {
              codeBlock
            }
          }
          ... on PRISMIC_PageBodyQuote {
            type
            label
            primary {
              quote
            }
          }
          ... on PRISMIC_PageBodyText {
            type
            label
            primary {
              text
            }
          }
          ... on PRISMIC_PageBodyImage {
            type
            label
            primary {
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 75) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        seo_description
        seo_meta_title
        featured_image
        featured_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default App;
